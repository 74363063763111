<template>
  <div class="layer-legend" :style="styleObject"></div>
</template>

<script type="text/babel">
  export default {
    name: 'LayerLegend',
    props: {        
        value: {
            type: Object,
            default: {}
        },
        size: {
            type: Number,
            default: 18
        }
    },
    components: { },
    data() {
        return {
            styleObject: {
                width: this.size + "px",
                height: this.size + "px"
            }
        };
    },
    methods: {
        applyStyles(value) {
            if (value) {
                let point;
                let linestring;
                let polygon;
                if ((point = value.hasOwnProperty("point")) || (linestring = value.hasOwnProperty("linestring")) || (polygon = value.hasOwnProperty("polygon"))) {
                    if (point) {
                        this.applyPointStyle(value.point);
                    }
                    if (linestring) {
                        this.applyLinestringStyle(value.linestring);
                    }
                    if (polygon) {
                        this.applyPolygonStyle(value.polygon);
                    }
                } else {
                    this.aplyGeneralStyle(this.value);
                }
            }
        },
        aplyGeneralStyle(value) {
            console.log(value);
        },
        applyPointStyle(value) {

        },
        applyLinestringStyle(value) {

        },
        applyPolygonStyle(value) {
            
        }
    },
    mounted() {
        this.applyStyles(this.value);
    }
  };
</script>
